<template>
  <div class="video-container iq-main-slider">
    <!-- 
      <video id="videoPlayer" autoplay="autoplay" muted controls>
  <source :src="video.content_url[$i18n.locale]">
</video>
      -->
    <iframe
      id="videoPlayer"
      :src="video.content_url[$i18n.locale] + '?autoplay=1'"
      frameborder="0"
      autoplay="autoplay"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      width="100%"
      height="100%"
    ></iframe>
  </div>
</template>
<script>
import AlertServie from '../../../services/errors'

export default {
  name: 'BannerVideo',
  props: ['video'],
  components: {},
  mounted () {
    var videoPlayer = document.getElementById('videoPlayer')

    // Add an event listener to detect when the video ends
    videoPlayer.addEventListener('ended', function () {
      // Send a message to the parent page indicating that the video has ended
      AlertServie.info(this.$i18n.t('rewatch'))
    })
  },
  data () {
    return {}
  }
}
</script>
