<template>
  <section id="iq-favorites">
    <b-container fluid>
      <b-row>
        <b-col sm="12" class="overflow-hidden">
          <div
            class="iq-main-header d-flex align-items-center justify-content-between"
          >
            <h4 class="main-title">
              {{ category[0]['content_category']?.[$i18n.locale] }}
              <br />
            </h4>
            <router-link
              :to="'/category/' + categoryID"
              class="iq-view-all"
              v-if="$i18n.locale == 'en'"
              >View All</router-link
            >
            <router-link
              :to="'/category/' + categoryID"
              class="iq-view-all"
              v-if="$i18n.locale == 'ku'"
            >
              هەموو نیشان بدە</router-link
            >
            <router-link
              :to="'/category/' + categoryID"
              class="iq-view-all"
              v-if="$i18n.locale == 'ar'"
              >عرض الكل</router-link
            >
          </div>
          <div class="upcoming-contens">
            <Slick
              class="favorites-slider list-inline row p-0 mb-0 iq-rtl-direction"
              ref="dSlick"
              :option="favOption"
            >
              <li
                class="slide-item"
                v-for="(item, index) in category"
                :key="index"
              >
                <div class="block-images position-relative">
                  <div class="img-box">
                    <img
                      :src="item.content_thumbnail"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <div class="block-description d-flex">
                    <h6 class="iq-title">
                      <a @click="gotoPage('/movie-detail/' + item.id)">{{
                        item.content_title?.[$i18n.locale]
                      }}</a>
                    </h6>

                    <div class="hover-buttons" v-if="$i18n.locale == 'en'">
                      <a
                        @click="gotoPage('/movie-detail/' + item.id)"
                        role="button"
                        class="btn btn-hover iq-button"
                        ><i class="fa fa-play mr-1" aria-hidden="true"></i>
                        Play Now
                      </a>
                    </div>

                    <div class="hover-buttons" v-if="$i18n.locale == 'ku'">
                      <a
                        @click="gotoPage('/movie-detail/' + item.id)"
                        role="button"
                        class="btn btn-hover iq-button"
                        ><i class="fa fa-play mr-1" aria-hidden="true"></i>
                        ڤیدیۆکە لێبدە
                      </a>
                    </div>

                    <div class="hover-buttons" v-if="$i18n.locale == 'ar'">
                      <a
                        @click="gotoPage('/movie-detail/' + item.id)"
                        role="button"
                        class="btn btn-hover iq-button"
                        ><i class="fa fa-play mr-1" aria-hidden="true"></i>
                        شغل الفيديو
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            </Slick>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>
import AlertServie from '../../../../services/errors'
export default {
  name: 'Itemx',
  props: ['category', 'categoryID'],
  components: {},
  mounted () {},
  data () {
    return {
      favOption: {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        autoplay: false,
        prevArrow:
          '<div class="slick-prev slick-arrow"><i class="fa fa-chevron-left"></i></div>',
        nextArrow:
          '<div class="slick-next slick-arrow"><i class="fa fa-chevron-right"></i></div>',
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    }
  },

  methods: {
    gotoPage (link) {
      var points = this.$store.state.points
      if (points > 0) {
        // AlertServie.info(this.$i18n.t('rewatch'))
        this.$router.push(link)
      } else {
        AlertServie.info(this.$i18n.t('points'))
      }
    },
    next () {
      this.$refs.dSlick.next()
    },
    prev () {
      this.$refs.dSlick.prev()
    }
  }
}
</script>
